import { ClassName } from "@/types";
import cn from "classnames";
import React, { FunctionComponent } from "react";

import styleCss from "./style.css";

const P: FunctionComponent<ClassName> = function ({ children, className }) {
  return <p className={cn(styleCss.paragraph, className)}>{children}</p>;
};

export default P;
