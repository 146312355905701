import { ApiError, InternalError, RequestError } from "redux-api-middleware";

type reduxApiMiddlewareError = InternalError | RequestError | ApiError;

export type City = string;

type Service = string;
export type Services = Service[];

type Phone = string;
type Phones = Phone[];

export type Dealer = {
  id: string;
  dealerId: string;
  name: string;
  city: City;
  value: string;
  coordinates: [number, number];
  creditEmails: string;
  serviceEmails: string;
  testDriveEmails: string;
};

export type State = {
  dealers?: Dealer[];
  loading: Boolean;
  error?: reduxApiMiddlewareError;
};

export const DEALERS_REQUEST = "DEALERS_REQUEST";
export const DEALERS_SUCCESS = "DEALERS_SUCCESS";
export const DEALERS_FAILURE = "DEALERS_FAILURE";

export type RequestDealersAction = {
  type: typeof DEALERS_REQUEST;
};

export type SuccessDealersAction = {
  type: typeof DEALERS_SUCCESS;
  payload: Dealer[];
};

export type FailureDealersAction = {
  type: typeof DEALERS_FAILURE;
  payload: reduxApiMiddlewareError;
};

export type DealersActionTypes = RequestDealersAction | SuccessDealersAction | FailureDealersAction;
