import { RSAA, RSAAAction } from "redux-api-middleware";
import resolveUrl from "resolve-url";

import config from "@/utils/config";
import resolveServiceFormFields from "@/utils/resolveServiceFormFields";

import {
  FORM_RESET,
  FORM_SAVE,
  FormActionTypes,
  POST_FORM_FAILURE,
  POST_FORM_REQUEST,
  POST_FORM_SUCCESS,
  State,
} from "./types";

export const formSave = (payload: Partial<State>): FormActionTypes => ({
  payload,
  type: FORM_SAVE,
});

export type FormSaveType = typeof formSave;

/**
 * Отправка заявки на кредит
 * @param fields поля формы
 */
export const postForm = (fields: any): RSAAAction => {
  fields.model = fields.modification;

  const code = fields.code;
  const formId = code === "by" ? config.get("formServiceIdBy") : config.get("formServiceId");

  return {
    [RSAA]: {
      endpoint: resolveUrl(config.get("formServiceApi"), "./data/", formId || config.get("formServiceId")),
      method: "POST",
      body: JSON.stringify({
        ...resolveServiceFormFields(fields),
        phone: "+" + fields.phone.replace(/\D/g, ""),
      }),
      types: [POST_FORM_REQUEST, POST_FORM_SUCCESS, POST_FORM_FAILURE],
    },
  };
};

export type PostFormType = typeof postForm;

/**
 * Сброс формы
 */
export const formReset = (): FormActionTypes => ({
  type: FORM_RESET,
});

export type FormResetType = typeof formReset;

export const getRecaptchaPublicKey = async () => {
  const response = await fetch(
    resolveUrl(config.get("formServiceApi"), "./forms/", config.get("formServiceIdBy") || config.get("formServiceId")),
    {
      method: "GET",
      headers: {
        Origin: "https://test.suzuki-ring.dev.perx.ru",
      },
    },
  );

  const { reCaptchaPublicKey } = (await response.json()) as any;

  return reCaptchaPublicKey;
};
