export const EVENT_ON_GA_TRACKING = "gaTracking";

export const FORM_IDS = {
  testDrive: "test-drive-form",
  creditCalc: "credit-calc-form",
  carInspection: "car-inspection-form",
  requestOnParticipation: "request-on-participation-form",
} as const;

export const PHONE_MASKS = {
  ru: {
    mask: "+7 999 999-99-99",
    pattern: /^\+\d \d\d\d \d\d\d\-\d\d\-\d\d$/,
  },
  by: {
    mask: "+375 (99) 999-99-99",
    pattern: /^\+\d\d\d [(]\d\d[)] \d\d\d\-\d\d\-\d\d$/,
  },
};

export const PHONE_CODE_OPTIONS = [
  { id: "ru", name: "Россия (+7)" },
  { id: "by", name: "Беларусь (+375)" },
];
