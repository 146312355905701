import cn from "classnames";
import React, { FC } from "react";

import styleCss from "./style.css";

type FieldHintProps = {
  type: "error"; // Тут можно расширять. На пример hint | notice | tip и.т.д.
};

const FieldHint: FC<FieldHintProps> = function ({ children, type = "error" }) {
  return <span className={cn(styleCss.hint, styleCss[type])}>{children}</span>;
};

export default FieldHint;
