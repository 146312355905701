import debounce from "lodash/debounce";
import { useEffect, useState } from "react";

export enum BreakPoints {
  ltMd = "(max-width: 767px)",
  md = "(min-width: 768px)",
  ltLg = "(max-width: 991px)",
  lg = "(min-width: 992px)",
  sm = "(min-width: 576px)",
}

const useMatchMedia = (mediaString: string) => {
  const [match, setMatch] = useState<boolean>();

  useEffect(() => {
    const media = window.matchMedia(mediaString);
    setMatch(media.matches);

    const resizeHandler = debounce(() => {
      const media = window.matchMedia(mediaString);
      setMatch(media.matches);
    }, 200);

    window.addEventListener("resize", resizeHandler);

    return () => window.removeEventListener("resize", resizeHandler);
  }, []);

  return match;
};

export default useMatchMedia;
