import RcSelect, { Option } from "rc-select";
import "rc-select/assets/index.css?global";
import { ISelectProps, valueType } from "rc-select/lib/PropTypes";
import React, { Component } from "react";

import Field, { FieldProps } from "../Field";
import FieldHint from "../FieldHint";

import styleCss from "./style.css";

export type onChangeType = (value: valueType, option: JSX.Element | JSX.Element[]) => void;

export type Item = { [key: string]: any } | string;

export interface SelectProps extends Omit<FieldProps, "hint">, Partial<ISelectProps> {
  options: ReadonlyArray<Item>;
  titleField: string | Function;
  valueField: string | Function;
  keyField: string | Function;
  error?: string;
}

// TODO: Возможно нужен рефакторинг Select т.к можно управлять без titleField и valueField используя "нативный" optionLabelProp
class Select extends Component<SelectProps> {
  static defaultProps: Partial<SelectProps> = {
    options: [],
    titleField: "value",
    valueField: "value",
    keyField: "value",
  };

  displayName = "Select";

  render() {
    const {
      className,
      label,
      options: sourceOptions,
      titleField,
      valueField,
      keyField,
      error,
      ...restProps
    } = this.props;

    const options = sourceOptions.map((option) => {
      let title;
      let value;
      let key;

      if (typeof option === "object") {
        [title, value, key] = [titleField, valueField, keyField].map((field) => {
          if (typeof field === "string") {
            return option[field];
          }

          return field(option);
        });
      } else {
        title = value = key = option;
      }

      const render = (
        <Option key={key} value={value}>
          {title}
        </Option>
      );

      return render;
    });

    let hint;

    if (error) {
      hint = <FieldHint type="error">{error}</FieldHint>;
    }

    return (
      <Field className={className} label={label} hint={hint}>
        <RcSelect
          className={styleCss.select}
          dropdownClassName={styleCss.dropdown}
          dropdownAlign={{ offset: [0, 0] }} // Убираем пробел м/у input`ом и dropdown меню
          {...restProps}
        >
          {options}
        </RcSelect>
      </Field>
    );
  }
}

export default Select;
