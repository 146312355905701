import cn from "classnames";
import React, { FunctionComponent, ReactNode } from "react";

import Label from "../Label";

import styleCss from "./style.css";

export interface FieldProps {
  label?: ReactNode; // Заголовок
  labelRight?: ReactNode; // Заголовок справа
  hint?: ReactNode; // Подсказка / ошибка
  className?: string;
  Tag?: "label" | "div";
}

const Field: FunctionComponent<FieldProps> = function ({
  children,
  label,
  labelRight,
  className,
  hint,
  Tag = "label",
}) {
  return (
    <Tag className={cn(styleCss.field, className)}>
      {label && (
        <Label>
          {label} {labelRight && <Label>{labelRight}</Label>}
        </Label>
      )}
      {children}
      {hint && <div className={styleCss.hint}>{hint}</div>}
    </Tag>
  );
};

export default Field;
