import { ClassName } from "@/types";
import cn from "classnames";
import { FunctionComponent, createElement } from "react";

import styleCss from "./style.css";

interface TitleProps extends ClassName {
  level?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
}

const Title: FunctionComponent<TitleProps> = function ({ children, level = "h1", className, ...rest }) {
  return createElement(level, { className: cn(styleCss.title, styleCss[level], className), ...rest }, children);
};

Title.displayName = "Title";

export default Title;
