import merge from "deepmerge";
import React from "react";
import { render, unmountComponentAtNode } from "react-dom";

import config, { Config } from "@/utils/config";
import polyfills from "@/utils/polyfills";

import("@/assets/css/main.css?global");
import("@/assets/icons/style.css?global");

const isDev = process.env.NODE_ENV === "development";

if (isDev) {
  import("@/assets/fonts/style.css?global");
  !!module.hot && module.hot.accept();
}

export function create(initConfig: Config = {}) {
  polyfills.then(() => {
    // У каждого виджета свой дефолтный конфиг
    const defaultConfig = require(`./apps/${ENV.widget}/defaultConfig`).default;

    // Все виджеты имеют selectorId и widget в своем конфиге
    config.set({
      selectorId: ENV.selectorId,
      widget: ENV.widget,
      ...merge(defaultConfig, initConfig),
    });

    // App подключается после установки параметров конфига т.к конфиг используется в App
    const App = require(`./apps/${ENV.widget}/`).default;
    const root = document.getElementById(config.get("selectorId")) as Element;

    unmountComponentAtNode(root);

    setTimeout(() => {
      render(<App initConfig={initConfig} />, root);
    }, 100);
  });
}

export function inspect() {
  return {
    version: ENV.version,
    // Данные о репозитории недоступны при сборке докер образа
    // commitHash: ENV.commitHash,
    // branchName: ENV.branchName,
    buildEnvironment: ENV.buildEnvironment,
    buildDate: ENV.buildDate,
    webpackParams: ENV.webpackParams,
    appConfig: config.getFullConfig(),
  };
}
