import config from "@/utils/config";

import { City, Dealer } from "../types/formServiceApi";

/**
 * Возвращает все города отсортированные по алфавиту
 */
export const getCitiesFromDealers = (dealers?: Dealer[]) => {
  if (!dealers) return;

  const city = config.get("city");

  const cities = dealers.reduce<City[]>((acc, { city }) => {
    const hasCity = acc.includes(city);

    if (!hasCity) {
      acc.push(city);
    }

    return acc;
  }, []);

  if (city) {
    return [city];
  }

  return cities.sort();
};

export default getCitiesFromDealers;

export type GetCitiesFromDealersType = ReturnType<typeof getCitiesFromDealers>;
