import { PHONE_CODE_OPTIONS, PHONE_MASKS } from "@/constants";
import cn from "classnames";
import React, { FC } from "react";
import InputMask from "react-input-mask";

import Input from "../Input";
import Select from "../Select";

import style from "./style.css";

type InputPhoneProps = {
  getFieldProps: Function;
  getError: Function;
  className?: string;
  required?: boolean;
  phoneSettings?: {
    mask: string;
    pattern: string;
  };

  /**
   * Если country/setFieldsValue пробросили,
   * будет работать селект с выбором кода странцы
   * */
  setFieldsValue?: Function;
  country?: string;
  isByCity?: boolean;
};

const InputPhone: FC<InputPhoneProps> = function ({
  getFieldProps,
  getError,
  className,
  required,
  phoneSettings,
  setFieldsValue,
  country,
  isByCity,
}) {
  const [code, setCode] = React.useState(country);

  const isSelect = (!!(setFieldsValue && code) && country === "by") || isByCity;
  const { mask, pattern } = isSelect ? PHONE_MASKS[code] : phoneSettings || PHONE_MASKS["ru"];

  React.useEffect(() => {
    if (isByCity) {
      setCode("by");
      setFieldsValue?.({ phone: "", code: "by" });
    }
  }, [isByCity]);

  React.useEffect(() => {
    setFieldsValue?.({ code: code || "ru" });
  }, []);

  return (
    <div className={cn({ [style.root]: isSelect }, className)}>
      {!!isSelect && (
        <>
          <Select
            options={PHONE_CODE_OPTIONS}
            titleField="name"
            valueField="id"
            keyField="id"
            optionLabelProp="children"
            optionFilterProp="children"
            defaultValue={code}
            value={code}
            onChange={(value) => {
              try {
                setFieldsValue?.({ phone: "", code: value });
                setCode(value as string);
              } catch (err) {}
            }}
            label={`Телефон${required ? "*" : ""}`}
            className={style.select}
          />
          <Input
            value={code}
            onChange={() => {}}
            style={{ display: "none" }}
            {...getFieldProps("code", {
              rules: [{ required: false }],
            })}
          />
        </>
      )}
      <InputMask
        mask={mask}
        {...getFieldProps("phone", {
          rules: [
            { required, message: "Укажите телефон" },
            {
              pattern,
              type: "string",
              message: `Укажите телефон в формате ${mask}`,
            },
          ],
        })}
        placeholder={mask}
        maskChar=""
        type="tel"
        error={getError("phone")}
        label={isSelect ? null : `Телефон${required ? "*" : ""}`}
      >
        {(props: any) => <Input className={cn({ [style.input]: isSelect })} {...props} />}
      </InputMask>
    </div>
  );
};

export default InputPhone;
