import { Dealer } from "../types/formServiceApi";
import config from "../utils/config";

/**
 * Возвращает отфильтрованных дилеров
 * @param state
 */
export const getFormServiceDealers = ({
  dealers,
  emailType,
}: {
  dealers?: Dealer[];
  emailType?: "creditEmails" | "serviceEmails" | "testDriveEmails";
}) => {
  const configDealerDcId = config.get("dealerDcId");

  const filteredDealers = dealers?.filter((dealer) => {
    // Проверяем наличие emails
    const hasEmails = emailType ? Boolean(dealer[emailType].trim()) : true;

    // Совпадение по дилеру
    const wantDealerId: string | undefined =
      config.get("dealerIdOverride") || window.Suzuki?.dealerId || config.get("dealerId");

    const matchByDealerId = wantDealerId ? wantDealerId === dealer.dealerId : true;

    // Совпадение по id ДЦ
    const matchByDealerDcId = configDealerDcId === dealer.id;

    const resolvedMatch = configDealerDcId && !config.get("dealerIdOverride") ? matchByDealerDcId : matchByDealerId;

    return hasEmails && resolvedMatch;
  });

  return filteredDealers;
};

export default getFormServiceDealers;

export type GetFormServiceDealersType = ReturnType<typeof getFormServiceDealers>;
