function test(value: any) {
  return !(typeof value === "function");
}

if (!window.Promise) {
  require("core-js/modules/es.array.iterator");
  require("core-js/modules/es.promise");
}

const polyfills = [
  ...(test(Object.assign)
    ? [import(/* webpackChunkName: "polyfill-es.object.assign" */ "core-js/modules/es.object.assign")]
    : []),
  ...(test([].find) ? [import(/* webpackChunkName: "polyfill-es.array.find" */ "core-js/modules/es.array.find")] : []),
  ...(test([].includes)
    ? [import(/* webpackChunkName: "polyfill-es.array.includes" */ "core-js/modules/es.array.includes")]
    : []),
  ...(test([].findIndex)
    ? [import(/* webpackChunkName: "polyfill-es.array.find-index" */ "core-js/modules/es.array.find-index")]
    : []),
  ...(test(window.URLSearchParams)
    ? [import(/* webpackChunkName: "polyfill-web.url-search-params" */ "core-js/modules/web.url-search-params")]
    : []),
  ...(test(window.fetch) ? [import(/* webpackChunkName: "polyfill-whatwg-fetch" */ "whatwg-fetch")] : []),
];

export default Promise.all(polyfills);
