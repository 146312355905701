import cn from "classnames";
import React, { FunctionComponent, HTMLAttributes } from "react";

import styleCss from "./style.css";

type LabelProps = HTMLAttributes<HTMLLabelElement>;

const Label: FunctionComponent<LabelProps> = function ({ children, className, ...restProps }) {
  return children ? (
    <label className={cn(styleCss.label, className)} {...restProps}>
      {children}
    </label>
  ) : null;
};

export default Label;
