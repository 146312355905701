import React, { FunctionComponent } from "react";
import { ApiError, InternalError, RequestError } from "redux-api-middleware";

import P from "@/apps/ui/components/P";

import styleCss from "./style.css";

export type ReduxApiMiddlewareErrorType = InternalError | RequestError | ApiError;

type ReduxApiMiddlewareErrorProps = {
  error?: ReduxApiMiddlewareErrorType;
};

const ReduxApiMiddlewareError: FunctionComponent<ReduxApiMiddlewareErrorProps> = function ({ error, children }) {
  if (!error) return <>{children}</>;

  let message;

  switch (error.name) {
    case "RequestError":
      message = "Ошибка сети. Проверьте интернет соединение.";
      break;
    default:
      message = "Ошибка приложения. Обратитесь к администратору сайта.";
      break;
  }

  return <P className={styleCss.error}>{message}</P>;
};

export default ReduxApiMiddlewareError;
