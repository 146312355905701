import cn from "classnames";
import React, { FC, MouseEventHandler, ReactNode } from "react";

import styleCss from "./style.css";

export type Icon = "arrow";

type ButtonProps = {
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  type?: "submit" | "button";
  title?: string;
  className?: string;
  icon?: Icon;
  wide?: boolean;
  href?: string;
  disabled?: boolean;
};

const Button: FC<ButtonProps> = function (props) {
  const { children, className, icon, wide, href, type, ...restProps } = props;

  const Component = href ? "a" : "button";

  return (
    <Component
      className={cn(
        styleCss.btn,
        styleCss["primary"],
        { [styleCss.wide]: wide },
        { [styleCss[`icon-${icon}`]]: icon },
        className,
      )}
      {...(href ? { href } : { type })}
      {...restProps}
    >
      {children}
    </Component>
  );
};

Button.defaultProps = {
  type: "button",
};

Button.displayName = "Button";

export default Button;
