import {
  FORM_RESET,
  FORM_SAVE,
  FormActionTypes,
  POST_FORM_FAILURE,
  POST_FORM_REQUEST,
  POST_FORM_SUCCESS,
  State,
} from "./types";

const initialState: State = {
  fields: {
    dealerId: {
      value: undefined,
    },
    city: {
      value: undefined,
    },
    name: {
      value: "",
    },
    phone: {
      value: "",
    },
    term: {
      value: true,
    },
  },
  formSubmited: false,
  loading: false,
  error: undefined,
};

export function formReducer(state = initialState, action: FormActionTypes): State {
  switch (action.type) {
    case FORM_SAVE:
      return {
        ...state,
        fields: {
          ...state.fields,
          ...action.payload,
        },
      };

    case POST_FORM_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case POST_FORM_SUCCESS:
      return {
        ...state,
        formSubmited: true,
        loading: false,
      };

    case POST_FORM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FORM_RESET:
      return {
        ...state,
        formSubmited: false,
        fields: {
          ...initialState.fields,
        },
      };

    default:
      return state;
  }
}
