import { FormEvent, useCallback, useState } from "react";

import applyOnSubmitHandler from "@/utils/applyOnSubmitHandler";
import config from "@/utils/config";

type useFormHandlersType = {
  form: any;
  submitAction: Function;
  formatValues?: Function;
};

export const useFormHandlers = (props: useFormHandlersType) => {
  const [isTrySubmit, setIsTrySubmited] = useState(false);
  const { form, submitAction, formatValues } = props;

  const onSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setIsTrySubmited(true);

      form.validateFields((errors: any, values: any) => {
        if (errors) return;

        const formattedValues = formatValues?.(values) ?? values;

        const resolvedValues = applyOnSubmitHandler(formattedValues, config.get("updateFieldsOnSubmit"));

        const result = submitAction(resolvedValues);

        if (result instanceof Promise) {
          result.then(() => setIsTrySubmited(false));
        }
      });
    },
    [submitAction, formatValues],
  );

  const getError = (field: string) => {
    const errors: string[] | undefined = form.getFieldError(field);
    return isTrySubmit ? errors && errors[0] : undefined;
  };

  return {
    isTrySubmit,
    setIsTrySubmited,
    onSubmit,
    getError,
  };
};
