import cn from "classnames";
import React, { Component } from "react";

import Field, { FieldProps } from "../Field";
import FieldHint from "../FieldHint";

import styleCss from "./style.css";

type InputProps = FieldProps &
  React.HTMLProps<HTMLInputElement> & {
    inputClassName?: string;
    type?: "text" | "number" | "tel" | "email";
    placeholder?: string;
    pattern?: string;
    title?: string;
    error?: string;
  };

class Input extends Component<InputProps> {
  static defaultProps = {
    type: "text",
  };

  displayName = "Input";

  render() {
    const { className, inputClassName, label, error, ...restProps } = this.props;

    let hint;

    if (error) {
      hint = <FieldHint type="error">{error}</FieldHint>;
    }

    return (
      <Field className={className} label={label} hint={hint}>
        <input className={cn(styleCss.input, inputClassName)} {...restProps} />
      </Field>
    );
  }
}

export default Input;
