import { Fields } from "@/types";

const rcFormStateToKeyValue = (stateFields: Fields) => {
  const fields = Object.entries(stateFields).reduce<{ [key: string]: any }>((acc, [name, field]) => {
    acc[name] = field.value;
    return acc;
  }, {});

  return fields;
};

export default rcFormStateToKeyValue;
