import { getFormServiceDealers } from "@/selectors";
import { createForm, createFormField } from "rc-form";
import { connect } from "react-redux";

import { AppState } from "../../store";
import { getDealers } from "../../store/dealers/actions";
import { formReset, formSave, postForm } from "../../store/form/actions";
import { FieldsKey, State as FormState } from "../../store/form/types";
import Form, { FormProps } from "./Form";

/**
 * Конфигурируем rc-form, чтобы значения полей формы хранились в redux
 */
const WrapedForm = createForm({
  mapPropsToFields(props: Omit<FormProps, "form">) {
    const fields: FieldsKey[] = ["dealerId", "city", "name", "phone", "term", "code", "g-recaptcha-response"];

    const formFields = fields.reduce<{ [key: string]: any }>((acc, name) => {
      acc[name] = createFormField(props.formFields[name]);
      return acc;
    }, {});

    return formFields;
  },
  onFieldsChange(props: Omit<FormProps, "form">, fields: Partial<FormState>) {
    const { formSave } = props;
    formSave(fields);
  },
})(Form);

/**
 * Конфигурируем connect
 */
const mapState = (state: AppState) => ({
  formFields: state.form.fields,
  dealers: getFormServiceDealers({
    dealers: state.dealers.dealers,
  }),
  formIsLoading: state.form.loading,
  formSubmited: state.form.formSubmited,
  formError: state.form.error,
  initialErrors: {
    dealers: state.dealers.error,
  },
});

const mapActions = {
  formSave,
  postForm,
  formReset,
  getDealers,
};

export default connect(mapState, mapActions)(WrapedForm);
