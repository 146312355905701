import { createSelector } from "reselect";

import { City } from "@/types/formServiceApi";

import getFormServiceDealers from "./getFormServiceDealers";

export const getFormServiceDealersForCurrentCity = createSelector(
  getFormServiceDealers,
  ({
    currentCity,
  }: {
    /** Текущий выбранный город */
    currentCity?: City;
  }) => currentCity,
  (dealers, currentCity) => {
    const filteredDealers = dealers?.filter(({ city }) => !currentCity || city === currentCity);

    const sortedDealers = filteredDealers?.sort((a, b) =>
      a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase() ? 1 : -1,
    );

    return sortedDealers;
  },
);

export default getFormServiceDealersForCurrentCity;

export type GetFormServiceDealersForCurrentCity = ReturnType<typeof getFormServiceDealersForCurrentCity>;
