import { Field, ReduxApiMiddlewareError } from "@/types";

export type Fields = {
  dealerId: Field<string>;
  city: Field<string>;
  name: Field<string>;
  phone: Field<string>;
  term: Field<boolean>;
  code?: Field<string>;
  "g-recaptcha-response"?: Field<string>;
};

export type City = string;

export type FieldsKey = keyof Fields;

export type Dealer = {
  id: string;
  name: string;
  city: City;
  coordinates: [number, number];
};

export type Error = ReduxApiMiddlewareError;

export interface State {
  fields: Fields;
  formSubmited: boolean;
  dealers?: Dealer[];
  loading: boolean;
  error?: Error;
}

export const FORM_SAVE = "FORM_SAVE";

interface SaveFormAction {
  type: typeof FORM_SAVE;
  payload: Partial<State>;
}

/**
 * Actions для отправки формы
 */
export const POST_FORM_REQUEST = "POST_FORM_REQUEST";
export const POST_FORM_SUCCESS = "POST_FORM_SUCCESS";
export const POST_FORM_FAILURE = "POST_FORM_FAILURE";

export type PostFormRequestAction = {
  type: typeof POST_FORM_REQUEST;
};

export type PostFormSuccessAction = {
  type: typeof POST_FORM_SUCCESS;
  payload: {
    comment: string;
    created: string;
    data: {
      [key in FieldsKey]: string;
    };
    formId: string;
    id: string;
    metadata: {
      gaClientID: string;
      hash: string;
      host: string;
      referer: string;
      userIP: string;
    };
    status: string;
    tags: string[];
    updated: string;
  };
};

export type PostFormFailureAction = {
  type: typeof POST_FORM_FAILURE;
  payload: ReduxApiMiddlewareError;
};

/**
 * Сброс формы
 */
export const FORM_RESET = "FORM_RESET";

export type FORMResetAction = {
  type: typeof FORM_RESET;
};

/**
 * Common action
 */
export type FormActionTypes =
  | SaveFormAction
  | PostFormRequestAction
  | PostFormSuccessAction
  | PostFormFailureAction
  | FORMResetAction;
