function resolveServiceFormFields(fields: any) {
  Object.keys(fields).forEach((name) => {
    if (fields[name] === undefined || fields[name] === "") {
      delete fields[name];
    }
  });

  return fields;
}

export default resolveServiceFormFields;
