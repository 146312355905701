import cn from "classnames";
import React, { ChangeEvent, Component, HTMLAttributes } from "react";

import Field, { FieldProps } from "../Field";
import FieldHint from "../FieldHint";

import style from "./style.css";

type CheckboxProps = {
  title?: string;
  className?: string;
  inputClassName?: string;
  boxProps?: Omit<HTMLAttributes<HTMLSpanElement>, "className">;
  error?: string;
  value?: boolean;
  disabled?: boolean;
} & HTMLAttributes<HTMLInputElement> &
  FieldProps;

type CheckboxState = {
  checked: boolean;
};

class Checkbox extends Component<CheckboxProps, CheckboxState> {
  constructor(props: CheckboxProps) {
    super(props);

    this.state = {
      checked: props.value ?? false,
    };
  }

  componentDidUpdate(...args: [CheckboxProps, CheckboxState]) {
    this.checkValue(...args);
  }

  checkValue = (prevProps: CheckboxProps, prevState: CheckboxState) => {
    if (prevProps.value !== this.props.value) {
      this.setState({
        checked: this.props.value ?? false,
      });
    }
  };

  onChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ checked: event.target.checked });
    this.props.onChange?.(event);
  };

  render() {
    const { children, className, inputClassName, boxProps, error, label, disabled } = this.props;

    const { checked } = this.state;

    let hint;

    if (error) {
      hint = <FieldHint type="error">{error}</FieldHint>;
    }

    return (
      <Field className={className} label={label} hint={hint}>
        <input
          type="checkbox"
          className={cn(style.native, inputClassName)}
          checked={checked}
          disabled={disabled}
          onChange={this.onChange}
        />
        <span
          tabIndex={0} // BUG: фокус есть, но нельзя управлять состоянием при помощи клавиатуры
          className={style.checkbox}
          {...boxProps}
        />
        {children && <span className={style.label}>{children}</span>}
      </Field>
    );
  }
}

export default Checkbox;
