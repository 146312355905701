import { applyMiddleware, combineReducers, createStore } from "redux";
import { apiMiddleware } from "redux-api-middleware";
import { composeWithDevTools } from "redux-devtools-extension";
import reduxThunk from "redux-thunk";

import { callbacksMiddleware } from "../middleware";
import dealersReducer from "./dealers/reducers";
import { formReducer } from "./form/reducers";

const rootReducer = combineReducers({
  dealers: dealersReducer,
  form: formReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default function configureStore() {
  const middlewares = [reduxThunk, apiMiddleware, callbacksMiddleware];

  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(rootReducer, composeWithDevTools(middleWareEnhancer));

  return store;
}
