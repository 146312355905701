import { DEALERS_FAILURE, DEALERS_REQUEST, DEALERS_SUCCESS, DealersActionTypes, State } from "./types";

const initialState: State = {
  loading: false,
  dealers: undefined,
};

export default function dealersReducer(state = initialState, action: DealersActionTypes): State {
  switch (action.type) {
    case DEALERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: undefined,
      };

    case DEALERS_SUCCESS:
      return {
        ...state,
        loading: false,
        dealers: action.payload,
      };

    case DEALERS_FAILURE:
      console.error(`Ошибка получения списка дилеров: ${action.payload.message}`);

      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
