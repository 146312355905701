import React, { FC } from "react";

import Input from "../Input";

type InputNameProps = {
  getFieldProps: Function;
  getError: Function;
  className?: string;
  required?: boolean;
};

const InputName: FC<InputNameProps> = function ({ getFieldProps, getError, className, required, ...restProps }) {
  return (
    <Input
      className={className}
      {...getFieldProps("name", {
        rules: [
          { required, message: "Укажите имя" },
          {
            validator: (_, value) =>
              !value.includes(" ") ? Promise.resolve() : Promise.reject(new Error("Укажите корректное значение")),
          },
        ],
      })}
      label={`Имя${required ? "*" : ""}`}
      error={getError("name")}
      {...restProps}
    />
  );
};

export default InputName;
