import { Middleware } from "redux";

const createFormCallbacksMiddleware = (params: {
  submitActionType: string;
  getFormStateSelector: Function;
  getOnSubmitCallback: Function;
}) => {
  const { submitActionType, getFormStateSelector, getOnSubmitCallback } = params;

  const callbacksMiddleware: Middleware<{}> = (store) => (next) => (action) => {
    if (action.type === submitActionType) {
      if (typeof getOnSubmitCallback === "function") {
        const formsState = getFormStateSelector(store.getState());
        getOnSubmitCallback(formsState);
      }
    }

    next(action);
  };

  return callbacksMiddleware;
};

export default createFormCallbacksMiddleware;
