import React from "react";
import { Provider } from "react-redux";

import App from "./components/App";
import createStore from "./store";

const store = createStore();

export default () => (
  <Provider store={store}>
    <App />
  </Provider>
);
