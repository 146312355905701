interface Config {
  [key: string]: any;
}

const config: Config = {};

function get(key: string[]): any[];
function get(key: string): any;
function get(key: string | string[]): any {
  let param;

  if (typeof key === "string") {
    param = config[key];
  } else if (Array.isArray(key)) {
    param = key.map((i) => config[i]);
  }

  return param;
}

function set(mod: object): void;
function set(key: string, value: any): void;
function set(key: object | string, value?: any) {
  if (typeof key === "object") {
    Object.assign(config, key);
  } else {
    config[key] = value;
  }
}

function getFullConfig() {
  return Object.freeze({ ...config });
}

export default {
  get,
  set,
  getFullConfig,
};

export { Config };
