import cn from "classnames";
import React, { FunctionComponent, ReactNode } from "react";
// tslint:disable-next-line: import-name
import ReactModal, { Props as ReactModalProps } from "react-modal";

import config from "@/utils/config";

import styleCss from "./style.css";

// 'selectorId' имеется в виджетах, но отсутствует в storybook
config.get("selectorId") && ReactModal.setAppElement(`#${config.get("selectorId")}`);

export interface ModalProps extends ReactModalProps {
  children?: ReactNode;
  className?: string;
  isOpen: boolean;
}

const Modal: FunctionComponent<ModalProps> = function (props) {
  const { children, className, overlayClassName, ...restProps } = props;

  return (
    <ReactModal
      className={cn(styleCss.modal, className)}
      overlayClassName={cn(styleCss.overlay, overlayClassName)}
      {...restProps}
    >
      {children}
    </ReactModal>
  );
};

Modal.displayName = "Modal";

export default Modal;
