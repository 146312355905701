import { createSelector } from "reselect";

import getCitiesFromDealers from "./getCitiesFromDealers";
import getFormServiceDealers from "./getFormServiceDealers";

/**
 * Возвращает все города отсортированные по алфавиту
 */
export const getFormServiceCities = createSelector(getFormServiceDealers, (dealers) => getCitiesFromDealers(dealers));

export default getFormServiceCities;

export type GetFormServiceCitiesType = ReturnType<typeof getFormServiceCities>;
