import React, { FC } from "react";
import { hot } from "react-hot-loader/root";

import Form from "../Form";

import styleCss from "./style.css";

const App: FC = function () {
  return (
    <section className={styleCss.app}>
      <Form />
    </section>
  );
};

export default hot(App);
