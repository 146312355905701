import React, { FC, useCallback } from "react";

import Button from "@/apps/ui/components/Button";
import Title from "@/apps/ui/components/Title";

import style from "./style.css";

export interface ErrorMessageTypes {
  onClick: Function;
}

const ErrorMessage: FC<ErrorMessageTypes> = ({ onClick }) => {
  const onClickCallback = useCallback(() => onClick(), []);

  return (
    <div className="row no-gutters">
      <div className="col-sm-8 mx-auto py-4">
        <div className={style.error}>
          <Title level="h5">Во время обработки запроса произошла ошибка, пожалуйста, попробуйте позже.</Title>
          <Button onClick={onClickCallback}>Повторить отправку</Button>
        </div>
      </div>
    </div>
  );
};

export default ErrorMessage;
