import { RSAA } from "redux-api-middleware";
import resolveUrl from "resolve-url";

import config from "@/utils/config";

import { DEALERS_FAILURE, DEALERS_REQUEST, DEALERS_SUCCESS } from "./types";

/**
 * Получить список дилеров
 */
export const getDealers = () => ({
  [RSAA]: {
    endpoint: resolveUrl(
      config.get("formServiceApi"),
      "./datasource/",
      `./${config.get("formServiceDatasource")}/`,
      "./feed?per_page=all",
    ),
    method: "GET",
    types: [DEALERS_REQUEST, DEALERS_SUCCESS, DEALERS_FAILURE],
  },
});

export type GetDealersType = typeof getDealers;
