import React, { FC } from "react";

import Select, { Item, SelectProps } from "../Select";

import style from "./style.css";

type DealerSelectProps = {
  getFieldProps: Function;
  getError: Function;
  options?: ReadonlyArray<Item>;
  required?: boolean;
  label?: string;
  placeholder?: string;
} & Partial<SelectProps>;

const DealerSelect: FC<DealerSelectProps> = function ({
  getFieldProps,
  getError,
  options,
  required,
  label,
  placeholder,
  ...restProps
}) {
  const resolvedPlaceholder = placeholder || "Выберите дилерский центр";
  const ref = React.useRef(null);

  return (
    <div className={style.root} ref={ref}>
      <Select
        {...getFieldProps("dealerId", {
          rules: [{ required, message: resolvedPlaceholder }],
        })}
        options={options}
        error={getError("dealerId")} // Ошибку показываем если только выбран город
        titleField="name"
        valueField="id"
        keyField="id"
        optionLabelProp="children"
        optionFilterProp="children"
        label={`${label || "Дилерский центр"}${required ? "*" : ""}`}
        placeholder={resolvedPlaceholder}
        getPopupContainer={() => ref.current}
        {...restProps}
      />
    </div>
  );
};

export default DealerSelect;
