import React, { EventHandler, FC, MouseEvent } from "react";

import Button from "@/apps/ui/components/Button";
import Modal, { ModalProps } from "@/apps/ui/components/Modal";
import P from "@/apps/ui/components/P";
import Title from "@/apps/ui/components/Title";

import style from "./style.css";

type ModalSuccessProps = {
  onOkClick: EventHandler<MouseEvent<HTMLButtonElement>>;
} & ModalProps;

const ModalSuccess: FC<ModalSuccessProps> = function (props) {
  const { onOkClick, ...restProps } = props;

  return (
    <Modal {...restProps} className={style.successModal}>
      <Title level="h2">Спасибо!</Title>
      <P>Ваша заявка принята. Мы свяжемся с вами в ближайшее время</P>
      <Button wide={true} onClick={onOkClick}>
        ОК
      </Button>
    </Modal>
  );
};

export default ModalSuccess;
