import cn from "classnames";
import React, { FC } from "react";

import Checkbox from "../Checkbox";

import style from "./style.css";

type TermCheckboxProps = {
  getFieldProps: Function;
  getFieldValue: Function;
  getError: Function;
  href: string;
  className?: string;
};

const TermCheckbox: FC<TermCheckboxProps> = function ({ getFieldProps, getError, getFieldValue, href, className }) {
  return (
    <Checkbox
      {...getFieldProps("term", {
        rules: [
          {
            validator: (options: object, value: boolean) => value,
            required: true,
            type: "boolean",
            message: "Нужно ваше согласие на обработку персональных данных",
          },
        ],
      })}
      defaultChecked={getFieldValue("term")}
      className={cn(className, style.term)}
      error={getError("term")}
    >
      Я согласен на обработку персональных данных
      <br />
      <a href={href} target="_blank" className={style.termLink}>
        Смотреть правила
      </a>
    </Checkbox>
  );
};

export default TermCheckbox;
