import config from "@/utils/config";
import createFormCallbacksMiddleware from "@/utils/createFormCallbacksMiddleware";
import rcFormStateToKeyValue from "@/utils/rcFormStateToKeyValue";

import getFormState from "../selectors/getFormState";
import { AppState } from "../store";
import { Fields, POST_FORM_REQUEST } from "../store/form/types";

const getFormStateSelector = (state: AppState) => {
  const fields = rcFormStateToKeyValue(getFormState(state).fields);

  return fields;
};

export const EVENT_ON_SUBMIT = "callFeedbackOnSubmit";

const getOnSubmitCallback = (fields: Fields) => {
  const onSubmit = config.get("onSubmit");

  if (typeof onSubmit === "function") {
    onSubmit(fields);
  }

  document.dispatchEvent(
    new CustomEvent(EVENT_ON_SUBMIT, {
      detail: fields,
    }),
  );
};

export const callbacksMiddleware = createFormCallbacksMiddleware({
  getOnSubmitCallback,
  getFormStateSelector,
  submitActionType: POST_FORM_REQUEST,
});

export default callbacksMiddleware;
