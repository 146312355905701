import { useFormHandlers } from "@/hooks";
import { GetFormServiceDealersForCurrentCity } from "@/selectors";
import cn from "classnames";
import uniq from "lodash/uniq";
import React, { FC, useCallback, useEffect, useMemo } from "react";
import { ApiError, InternalError, RequestError } from "redux-api-middleware";

import Button from "@/apps/ui/components/Button";
import CitySelect from "@/apps/ui/components/CitySelect";
import DealerSelect from "@/apps/ui/components/DealerSelect";
import ErrorMessage from "@/apps/ui/components/ErrorMessage";
import InputName from "@/apps/ui/components/InputName";
import InputPhone from "@/apps/ui/components/InputPhone";
import ModalSuccess from "@/apps/ui/components/ModalSuccess";
import ReduxApiMiddlewareError from "@/apps/ui/components/ReduxApiMiddlewareError";
import TermCheckbox from "@/apps/ui/components/TermCheckbox";
import { RecaptchaWidget, useCaptcha } from "@/apps/ui/widgets/recaptcha/recaptcha";

import config from "@/utils/config";
import getPhoneSettings from "@/utils/getPhoneSettings";

import { getDealers } from "../../store/dealers/actions";
import { FormResetType, FormSaveType, PostFormType } from "../../store/form/actions";
import { Error, Fields } from "../../store/form/types";

import style from "./style.css";

export interface FormProps {
  form: any;
  formFields: Fields;
  getDealers: typeof getDealers;
  postForm: PostFormType;
  formReset: FormResetType;
  formIsLoading: boolean;
  dealers: GetFormServiceDealersForCurrentCity;
  formSave: FormSaveType;
  formSubmited: boolean;
  formError: Error;
  initialErrors: {
    dealers: InternalError | RequestError | ApiError;
  };
}

const Form: FC<FormProps> = function ({
  form: { getFieldProps, getFieldError, getFieldValue, setFieldsValue, validateFields },
  formIsLoading,
  initialErrors,
  postForm,
  formReset,
  getDealers,
  dealers,
  formSubmited,
  formError,
}) {
  const configDealerId = config.get("dealerId") || window.Suzuki?.dealerId;
  const isOnlyOneDealer = !!configDealerId;

  const country = config.get("country") || "ru";
  const phoneSettings = configDealerId && getPhoneSettings(country);

  const city = getFieldValue("city");

  const cities = useMemo(() => {
    if (!dealers) return [];
    return uniq(dealers.map((dealer) => dealer.city));
  }, [dealers]);

  const formatValues = useCallback(
    (values) => {
      if (!!values?.city) return values;
      const city = dealers?.find((dealer) => dealer.id === values?.dealerId)?.city;
      return {
        ...values,
        city,
      };
    },
    [dealers],
  );

  const { onSubmit, getError } = useFormHandlers({
    submitAction: postForm,
    formatValues,
    form: { getFieldError, validateFields },
  });

  // const { Recaptcha, ...captcha } = useCaptcha({
  //   onVerify: (res) => {
  //     setFieldsValue({ "g-recaptcha-response": res });
  //   },
  //   onExpire: () => {
  //     setFieldsValue({ "g-recaptcha-response": null });
  //   },
  // });

  const dealerOptions = useMemo(() => {
    if (!dealers) return [];
    if (!isOnlyOneDealer || !city) return dealers;
    return dealers.filter((dealer) => dealer.city === city);
  }, [dealers, isOnlyOneDealer, city, cities]);

  const isSingleDealer = dealerOptions?.length === 1;

  const hiddenCitySelect =
    !dealerOptions.length ||
    !isOnlyOneDealer ||
    (isSingleDealer && !isOnlyOneDealer) ||
    (isOnlyOneDealer && cities.length < 2);

  const hiddenDealerSelect = !dealerOptions.length || isSingleDealer || (isOnlyOneDealer && !city && cities.length > 1);

  useEffect(() => {
    getDealers();
  }, []);

  useEffect(() => {
    if (isSingleDealer && dealerOptions?.[0].id !== getFieldValue("dealerId")) {
      setFieldsValue({ dealerId: dealerOptions?.[0].id });
    }
  }, [dealerOptions]);

  useEffect(() => {
    if (isOnlyOneDealer && dealerOptions) {
      const dealerId = dealerOptions.length === 1 ? dealerOptions[0].id : undefined;
      setFieldsValue({ dealerId });
    }
  }, [isOnlyOneDealer, city]);

  if (initialErrors.dealers) {
    return <ErrorMessage onClick={getDealers} />;
  }

  return (
    <>
      <form onSubmit={onSubmit} noValidate={true}>
        <div>
          <InputName className={style.field} getFieldProps={getFieldProps} getError={getError} required={true} />
        </div>
        <div>
          <InputPhone
            className={style.field}
            getFieldProps={getFieldProps}
            getError={getError}
            required={true}
            phoneSettings={phoneSettings}
            setFieldsValue={setFieldsValue}
            country={country}
          />
        </div>

        {/* Скрываем отображение в режиме дилера, если один регион */}
        <div className={cn({ "d-none": hiddenCitySelect })}>
          <CitySelect
            className={style.field}
            label="Регион"
            placeholder="Выберите регион"
            getFieldProps={getFieldProps}
            getError={getError}
            error={getError("city")}
            options={cities}
            required={!hiddenCitySelect}
          />
        </div>

        {/* Скрываем отображение в режиме дилера, если один ДЦ */}
        <div className={cn({ "d-none": hiddenDealerSelect })}>
          <DealerSelect
            className={style.field}
            label="Дилер"
            placeholder="Выберите дилера"
            getFieldProps={getFieldProps}
            getError={getError}
            error={getError("dealerId")}
            options={dealerOptions}
            required={true}
          />
        </div>

        <div>
          <TermCheckbox
            className={style.termCheckbox}
            getFieldProps={getFieldProps}
            getError={getError}
            getFieldValue={getFieldValue}
            href={config.get("termsLink")}
          />
        </div>

        {/* <div>
          <Recaptcha getFieldProps={getFieldProps} {...captcha.bind} />
        </div> */}

        <div>
          <ReduxApiMiddlewareError error={formError} />
          {/* <Button wide={true} icon="arrow" type="submit" disabled={formIsLoading || captcha.state.isDisabled}>
            Отправить заявку
          </Button> */}
          <Button wide={true} icon="arrow" type="submit" disabled={formIsLoading}>
            Отправить заявку
          </Button>
        </div>
      </form>
      <ModalSuccess isOpen={formSubmited} onOkClick={formReset} />
    </>
  );
};

export default Form;
